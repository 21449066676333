export const CardsData = [
	{
		id: 1,
		// image: serviceOne,
		title: "TRUCKING SERVICES",
		heading:
			"For four years, Buraq Global Logistics has provided quality service and efficient handling of loads, including refrigerated, flatbed, and specialized freight. With one of the best safety records in the nation, our team is here to help.",
	},
	{
		id: 2,
		// image: serviceTwo,
		title: "MAINTENANCE & BODY SERVICES",
		heading:
			"Our large, full-service shop is staffed with experts committed to helping drivers get back on the road quickly, safely and affordably. Our team will keep you going places.",
	},
	{
		id: 3,
		// image: serviceThree,
		title: "MOBILE TRUCK REPAIR",
		heading:
			"At Buraq Global Logistics, our mobile truck repair services ensure your vehicles get back on the road quickly. Our skilled technicians handle on-site repairs efficiently, minimizing downtime and keeping your fleet moving.",
	},
];
