import React from 'react'
import BlogsHeroSection from './BlogsHeroSection'
import OurBlogs from '../ourBlogs/OurBlogs'

const Blogs = () => {
  return (
    <>
      <BlogsHeroSection />
      <OurBlogs />
    </>
  )
}

export default Blogs
