import React from 'react'
import { PageContainer } from '../PageStyles'
import DriveWithUs from '../../components/driveWithUs/DriveWithUs'

const DriveWithUsPage = () => {
  return (
    <PageContainer>
      <DriveWithUs />
    </PageContainer>
  )
}

export default DriveWithUsPage
