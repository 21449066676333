import React from 'react'
import { PageContainer } from '../PageStyles'
import Services from '../../components/services/Services'

const ServicesPage = () => {
  return (
    <PageContainer>
      <Services />
    </PageContainer>
  )
}

export default ServicesPage
