import clientOne from '../../../assets/images/clientOne.png'
import YukiNakamura from '../../../assets/images/YukiNakamura.jpg'
import AmaraPatel from '../../../assets/images/AmaraPatel.jfif'
import LarsSvensson from '../../../assets/images/LarsSvensson.jfif'
import FatimaElSayed from '../../../assets/images/FatimaEl-Sayed.jfif'
import IvanPetrov from '../../../assets/images/IvanPetrov.jfif'
import MariaRossi from '../../../assets/images/MariaRossi.jfif'
import LiWei from '../../../assets/images/LiWei.jfif'
import AminaDiallo from '../../../assets/images/AminaDiallo.jfif'
import JürgenMüller from '../../../assets/images/JürgenMüller.jfif'

export const reviews = [
    { 
      id: 1, 
      client: clientOne, 
      name: 'Alejandro García', 
      title: 'Best Quality', 
      heading: "I've been using Buraq Global Logistics for all my car maintenance needs, and they never disappoint. Recently, I needed an oil change and tire replacement, and their service was quick and efficient. The staff is knowledgeable and always goes the extra mile to ensure everything is perfect. Highly recommended!",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 2, 
      client: YukiNakamura, 
      name: 'Yuki Nakamura', 
      title: 'Professionalism', 
      heading: "During a long road trip, my car broke down, and I was stranded on the highway. Buraq Global Logistics came to the rescue with their towing and emergency roadside assistance services. They arrived promptly and got my car running again with a quick jump-start. Their professionalism and reliability are unmatched!",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 3, 
      client: AmaraPatel, 
      name: 'Amara Patel', 
      title: 'Reliable', 
      heading: "I've had issues with my car's tires for a while, and after visiting Buraq Global Logistics, I finally found a reliable place for tire repair and replacement. The team is friendly, and they offer great prices. I'm very satisfied with their services and will definitely return for future maintenance needs.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 4, 
      client: LarsSvensson, 
      name: 'Lars Svensson', 
      title: 'Value', 
      heading: "Buraq Global Logistics has been my go-to for car maintenance ever since I moved to New York. Their oil change service is fast and affordable. They even offer a complimentary check-up, ensuring my car is in top condition. I trust them completely with my vehicle.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 5, 
      client: FatimaElSayed, 
      name: 'Fatima El-Sayed', 
      title: 'Life Saver', 
      heading: "I had a flat tire in the middle of the night and was worried about finding help. Buraq Global Logistics' emergency roadside assistance came through for me. Their technician arrived swiftly and replaced my tire efficiently. Their 24/7 service is a lifesaver!",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 6, 
      client: IvanPetrov, 
      name: 'Ivan Petrov', 
      title: 'Reliable Service', 
      heading: "My car wouldn't start one morning, and I was late for work. I called Buraq Global Logistics for a jump-start, and they arrived in no time. Their team was friendly and got my car running quickly. I'm very impressed with their prompt and reliable service.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 7, 
      client: MariaRossi, 
      name: 'Maria Rossi', 
      title: 'Top Quality', 
      heading: "I needed a comprehensive car maintenance check, and Buraq Global Logistics exceeded my expectations. From the oil change to the tire replacement, their attention to detail was outstanding. The staff is courteous, and the service quality is top-notch.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 8, 
      client: LiWei, 
      name: 'Li Wei', 
      title: 'Excellent Services', 
      heading: "Buraq Global Logistics' towing service is exceptional. I had an accident, and their team arrived promptly, handled my car with care, and towed it to their workshop. They kept me updated throughout the process and provided excellent customer service. I highly recommend them.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 9,
      client: AminaDiallo, 
      name: 'Amina Diallo', 
      title: 'Efficient Service', 
      heading: "My car battery died unexpectedly, and I was stuck in a parking lot. Buraq Global Logistics' emergency roadside assistance saved the day with a quick jump-start. Their technician was professional and courteous. I'm grateful for their reliable and efficient service.",
      rating: "☆☆☆☆☆",
    },
    { 
      id: 10, 
      client: JürgenMüller, 
      name: 'Jürgen Müller', 
      title: 'Customer Satisfaction', 
      heading: "I've been a loyal customer of Buraq Global Logistics for years. Their tire repair and replacement services are the best in town. The team is skilled, and they always provide honest advice. I appreciate their dedication to quality and customer satisfaction.",
      rating: "☆☆☆☆☆",
    },
  ];